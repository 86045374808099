import React from "react";
import {
  SEO,
  PageLayout,
  Image,
  HookComponentProvider,
  ContactForm,
  LocationsContext,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { useContext } from "react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getComponentFiles } from "../utils/utils";

export default class CateringPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getComponentFiles({
            components: fishermanBusinessWebsitePage.components,
            componentName: "HeroElement",
            numToSelect: 1,
          })}
        />
        <PageLayout hero={null}>
          <Image
            src={
              "https://fisherman.gumlet.io/public/f5800c80-941a-45de-9267-7716e178c9d2/3_w2fJT7G.png"
            }
            className={"page-background-image"}
            background={true}
          >
            <div />
          </Image>
          <Grid
            stackable={true}
            className={"menu-container"}
            verticalAlign={"middle"}
            textAlign={"center"}
          >
            <Grid.Column width={"14"} textAlign={"center"}>
              <p className={"large-text"}>
                At Cosmic Burrito, we're proud to offer catering services for
                all of your special events and gatherings. Whether you're
                hosting a corporate function, a wedding, or just a backyard BBQ,
                our food truck will bring the flavors of the cosmos to your
                guests. Our menu features a variety of delicious burritos and
                tacos, as well as vegetarian options and refreshing drinks. We
                take pride in using only the freshest ingredients and delivering
                bold, unforgettable flavors in every dish. Let us handle the
                food so you can focus on enjoying your event. Contact us today
                to learn more about our catering options and how we can make
                your next gathering a truly cosmic experience!
              </p>
            </Grid.Column>
            <Grid.Column width={"12"} textAlign={"left"}>
              <div className={"contact-form-container"}>
                <HookComponentProvider
                  hook={useContext}
                  component={
                    <ContactForm
                      constructMessage={({
                        name,
                        email,
                        eventDate,
                        phone,
                        startTime,
                        guest,
                        eventType,
                        address,
                        service,
                      }) =>
                        `Someone has reached out to you via your website catering form: Name: ${name}, Email: ${email}, Phone: ${phone}, Event Date: ${eventDate}, Serving Time: ${startTime}, Number of People: ${guest}, Address: ${address}, Event Type: ${eventType}, Service: ${service}`
                      }
                      fields={[
                        {
                          label: "name",
                          placeholder: "Name",
                          type: "input",
                          required: true,
                        },
                        {
                          label: "email",
                          displayLabel: "Email",
                          placeholder: "Email",
                          type: "input",
                          inputType: "email",
                          required: true,
                        },
                        {
                          label: "phone",
                          displayLabel: "Phone Number",
                          placeholder: "Phone Number",
                          type: "input",
                          required: true,
                        },
                        {
                          label: "eventType",
                          displayLabel: "Event Type",
                          placeholder: "Event Type",
                          type: "input",
                          required: true,
                        },
                        {
                          label: "guest",
                          displayLabel: "Number Of People",
                          placeholder: "Number Of People",
                          type: "input",
                          required: true,
                        },
                        {
                          label: "eventDate",
                          displayLabel: "Date of Event",
                          placeholder: "Date of Event",
                          type: "input",
                          inputType: "date",
                          required: true,
                        },
                        {
                          label: "address",
                          displayLabel: "Address of Event",
                          placeholder: "Address of Event",
                          type: "input",
                          required: true,
                        },
                        {
                          label: "startTime",
                          displayLabel: "Serving Time At Event",
                          placeholder: "Serving Time At Event",
                          type: "input",
                          inputType: "time",
                          required: false,
                        },
                        {
                          label: "service",
                          displayLabel: "Food Truck or Catering",
                          placeholder: "Food Truck or Catering",
                          type: "select",
                          required: true,
                          options: [
                            { label: "Food Truck", value: "Food Truck" },
                            { label: "Catering", value: "Catering" },
                          ],
                        },
                      ]}
                      buttonText={"Send"}
                      centeredHeaders={true}
                      className={""}
                      fluidButton={true}
                      header={""}
                      inline={false}
                      showLabels={true}
                      subheader={""}
                      termsCopy={""}
                      withContainer={true}
                      locationId={fishermanBusiness.primaryLocation._id}
                      businessId={fishermanBusiness._id}
                      businessType={fishermanBusiness.type}
                    />
                  }
                  hookToPropsMap={{ locationId: "activeLocation.id" }}
                  hookParams={[LocationsContext]}
                />
                <p className={"italic-text"}>
                  Thank you! We will get back to you within 48 hours.
                </p>
              </div>
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
      primaryLocation {
        _id
      }
      _id
      type
    }
    fishermanBusinessWebsitePage(pageType: { eq: "Catering" }) {
      title
      components {
        fastId
        componentIdentifier
        order
        files {
          file
        }
      }
    }
  }
`;
